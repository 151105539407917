@font-face {
	font-family: 'Hillter Icon';
	src:url('../../fonts/hillterIcon/icomoon.eot?-kpo47j');
	src:url('../../fonts/hillterIcon/icomoon.eot?#iefix-kpo47j') format('embedded-opentype'),
		url('../../fonts/hillterIcon/icomoon.woff?-kpo47j') format('woff'),
		url('../../fonts/hillterIcon/icomoon.ttf?-kpo47j') format('truetype'),
		url('../../fonts/hillterIcon/icomoon.svg?-kpo47j#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="hillter-icon-"], [class*=" hillter-icon-"] {
	font-family: 'Hillter Icon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.hillter-icon-person:before {
	content: "\e600";
}
.hillter-icon-quote-left:before {
	content: "\e601";
}
.hillter-icon-breakfast:before {
	content: "\e602";
}
.hillter-icon-decor:before {
	content: "\e603";
}
.hillter-icon-bed:before {
	content: "\e604";
}
.hillter-icon-telephone:before {
	content: "\e605";
}
.hillter-icon-hangers:before {
	content: "\e606";
}
.hillter-icon-phone:before {
	content: "\e607";
}
.hillter-icon-ocenview:before {
	content: "\e608";
}
.hillter-icon-calendar:before {
	content: "\e609";
}
.hillter-icon-cart:before {
	content: "\e60a";
}
.hillter-icon-wifi:before {
	content: "\e60b";
}
.hillter-icon-arrow:before {
	content: "\e60c";
}
.hillter-icon-air-conditioner:before {
	content: "\e60d";
}
.hillter-icon-cable:before {
	content: "\e60e";
}
.hillter-icon-luxury:before {
	content: "\e60f";
}
.hillter-icon-location:before {
	content: "\e610";
}
.hillter-icon-size:before {
	content: "\e611";
}
.hillter-icon-cloud:before {
	content: "\e612";
}
.hillter-icon-view:before {
	content: "\e613";
}
.hillter-icon-time:before {
	content: "\e614";
}
.hillter-icon-bar:before {
	content: "\e615";
}
.hillter-icon-dressing-table:before {
	content: "\e616";
}
.hillter-icon-bar-coffee:before {
	content: "\e617";
}
.hillter-icon-microphone:before {
	content: "\e618";
}
.hillter-icon-media-play:before {
	content: "\e619";
}
.hillter-icon-down-arrow:before {
	content: "\e61a";
}
.hillter-icon-cooker-hood:before {
	content: "\e61b";
}
.hillter-icon-left-arrow:before {
	content: "\e61c";
}
.hillter-icon-right-arrow:before {
	content: "\e61d";
}
