/* You can add global styles to this file, and also import other style files */
/*------------------------------------------------------------------------
# Project Hillter
# ------------------------------------------------------------------------
# Designed by MegaDrupal
# Websites:  http://www.megadrupal.com -  Email: info@megadrupal.com
--------------------------------------------------------------------------

    1.   Shortcode
    2.   Header
    3.   Banner
    4.   Home
    5.   Gallery
    6.   Blog
    7.   Restaurants
    8.   Room
    9.   Activiti
    10.   Condition
    11.   Checkout
    12.   Attractions
    13.   About
    14.   Guestbook
    15.   Comingsoon
    16.   Account
    17.   Page 404
    18.   Contact
    19.   Page Shortcode
    20.   Footer
    21.   Responsive

--------------------------------------------------------------------------------*/
/* 0. Fonts
---------------------------------------------------------------------------------*/
@font-face {
    font-family: "NeueHaasUnicaPro";
    src: url("assets/fonts/NeueHaasUnica/Regular/f253a48ea830051f9e7d01c06ad2030d.eot");
    src: url("assets/fonts/NeueHaasUnica/Regular/444aaf682416d4f7d701a3cb36abbca2.woff") format("woff"),
    url("assets/fonts/NeueHaasUnica/Regular/5a593cd80bd3e9004a4d3c238a4d4e65.woff2") format("woff2"),
    url("assets/fonts/NeueHaasUnica/Regular/124ae928a417501827f69982d74691fd.ttf") format("ttf");
    font-weight: normal;
    }

@font-face {
    font-family: "NeueHaasUnicaPro";
    src: url("assets/fonts/NeueHaasUnica/Thin/02f563536dc18417039ed08b09128f70.eot");
    src: url("assets/fonts/NeueHaasUnica/Thin/66f1670c6f69857eb89d39e63f1cfe3f.woff") format("woff"),
    url("assets/fonts/NeueHaasUnica/Thin/0d96dfef158be7f96e4948a5e3940fdc.woff2") format("woff2"),
    url("assets/fonts/NeueHaasUnica/Thin/db5582d7b587b71a1e2f64a472698d95.ttf") format("ttf");
    font-weight: 100;
    }

@font-face {
    font-family: "NeueHaasUnicaPro";
    src: url("assets/fonts/NeueHaasUnica/Medium/7d5d94ce75a3b6cdb8b517724ee5dd42.eot");
    src: url("assets/fonts/NeueHaasUnica/Medium/a038ce4d77859fd048e4471bd6d600e9.woff") format("woff"),
    url("assets/fonts/NeueHaasUnica/Medium/f2ccacd1d4355494f6ddc9247e9dde53.woff2") format("woff2"),
    url("assets/fonts/NeueHaasUnica/Medium/dba15f9985758d8b24b8f6021ce721f2.ttf") format("ttf");
    font-weight: bold;
    }

@font-face {
    font-family: "NeueHaasUnicaPro";
    src: url("assets/fonts/NeueHaasUnica/Light/c08a941f14084ef95c0bf826839bd7a6.eot");
    src: url("assets/fonts/NeueHaasUnica/Light/fe13f1d7121008ae3562fbfe937d14db.woff") format("woff"),
    url("assets/fonts/NeueHaasUnica/Light/9eef8add2a25473a48fa894eca1a0560.woff2") format("woff2"),
    url("assets/fonts/NeueHaasUnica/Light/7465ce79057379ce96c8acdf854ede62.ttf") format("ttf");
    font-weight: 300;
    }
/*
@font-face {
    font-family: "NeueHaasUnicaPro";
    src: url("assets/fonts/NeueHaasUnica/Italic/c60d34a55e41ca545c30fded8ede3aca.eot");
    src: url("assets/fonts/NeueHaasUnica/Italic/7f1e2642cce7f0fbb7b5a00a6e728868.woff") format("woff"),
    url("assets/fonts/NeueHaasUnica/Italic/4f386daa018e654d09726d03b671ea1c.woff2") format("woff2"),
    url("assets/fonts/NeueHaasUnica/Italic/cf5eeaa11cfe9dfad7347fc1903b7b87.ttf") format("ttf");

    } */
/*
p{
    font-family: NeueHaasUnicaPro-Regular,sans-serif;
}
a{
    font-family: NeueHaasUnicaPro-Regular,sans-serif;
}
h2{
    font-family: NeueHaasUnicaPro-Thin,sans-serif;
}

.font-f-thin{
    font-family: NeueHaasUnicaPro-Thin,sans-serif !important;
}
.font-f-medium{
    font-family: NeueHaasUnicaPro-Medium,sans-serif !important;
}
.font-f-regular{
    font-family: NeueHaasUnicaPro-Regular,sans-serif !important;
}
.font-f-light{
    font-family: NeueHaasUnicaPro-Light,sans-serif !important;
}
.font-f-italic{
    font-family: NeueHaasUnicaPro-Italic,sans-serif !important;
} */

@font-face {
    font-family:icons;
    font-style:normal;
    font-weight:400;
    src:url(/assets/fonts/PiconsThin/037f60bcb17a83a9c151a32b5cfafff4.eot);
    src:url(/assets/fonts/PiconsThin/037f60bcb17a83a9c151a32b5cfafff4.eot?#iefix)format("eot"),
    url(/assets/fonts/PiconsThin/eb0b6130bf104ebd27363eaf54611ab6.woff) format("woff"),
    url(/assets/fonts/PiconsThin/9bdeee4c17166de7975067984ec453b8.ttf) format("truetype")
}

/* devanagari */
@font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 300;
    src: local('Hind Light'), local('Hind-Light'), url(/assets/fonts/hind/5aU19_a8oxmIfMJaER2SjQpf.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
    }
    /* latin-ext */
    @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 300;
    src: local('Hind Light'), local('Hind-Light'), url(/assets/fonts/hind/5aU19_a8oxmIfMJaERKSjQpf.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 300;
    src: local('Hind Light'), local('Hind-Light'), url(/assets/fonts/hind/5aU19_a8oxmIfMJaERySjQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* devanagari */
    @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    src: local('Hind Regular'), local('Hind-Regular'), url(/assets/fonts/hind/5aU69_a8oxmIdGh4BCOz.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
    }
    /* latin-ext */
    @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    src: local('Hind Regular'), local('Hind-Regular'), url(/assets/fonts/hind/5aU69_a8oxmIdGd4BCOz.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    src: local('Hind Regular'), local('Hind-Regular'), url(/assets/fonts/hind/5aU69_a8oxmIdGl4BA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* devanagari */
    @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 500;
    src: local('Hind Medium'), local('Hind-Medium'), url(/assets/fonts/hind/5aU19_a8oxmIfJpbER2SjQpf.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
    }
    /* latin-ext */
    @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 500;
    src: local('Hind Medium'), local('Hind-Medium'), url(/assets/fonts/hind/5aU19_a8oxmIfJpbERKSjQpf.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 500;
    src: local('Hind Medium'), local('Hind-Medium'), url(/assets/fonts/hind/5aU19_a8oxmIfJpbERySjQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* devanagari */
    @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 600;
    src: local('Hind SemiBold'), local('Hind-SemiBold'), url(/assets/fonts/hind/5aU19_a8oxmIfLZcER2SjQpf.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
    }
    /* latin-ext */
    @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 600;
    src: local('Hind SemiBold'), local('Hind-SemiBold'), url(/assets/fonts/hind/5aU19_a8oxmIfLZcERKSjQpf.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 600;
    src: local('Hind SemiBold'), local('Hind-SemiBold'), url(/assets/fonts/hind/5aU19_a8oxmIfLZcERySjQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* cyrillic-ext */
    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(/assets/fonts/montserrat/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }
    /* cyrillic */
    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(/assets/fonts/montserrat/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    /* vietnamese */
    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(/assets/fonts/montserrat/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }
    /* latin-ext */
    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(/assets/fonts/montserrat/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(/assets/fonts/montserrat/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* cyrillic-ext */
    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(/assets/fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3gTD_u50.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }
    /* cyrillic */
    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(/assets/fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    /* vietnamese */
    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(/assets/fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3gbD_u50.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }
    /* latin-ext */
    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(/assets/fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(/assets/fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
    
.Icon:before {
    display:inline-block;
    font-family:icons;
    -moz-osx-font-smoothing:grayscale;
    -webkit-font-smoothing:antialiased;
    font-style:normal;
    font-weight:400;
    line-height:1
}


.Icon--ancillaryInsurance:before {
    content:"\EA1B"
}

.Icon--ancillaryConcierge:before {
    content:"\EA19"
}

.Icon--faresDiscount:before {
    content:"\EA60"
}
/*-------------------------------------------------------------------------------*/
/* 1.   Shortcode
--------------------------------------------------------------------------------*/
.m-0{
    margin: 0px !important;
}
.m-l-0 {
    margin-left: 0 !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-40 {
    margin-top: 20px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-40 {
    margin-bottom: 40px !important;
}

.m-t-60 {
    margin-top: 60px !important;
}

.m-r-5 {
    margin-right: 5px !important;
}

.p-t-20 {
    padding-top: 20px !important;
}

.p-t-40 {
    padding-top: 40px !important;
}


.p-l-20 {
    padding-left: 20px !important;
}

.form-field-error {
    color: red;
}

.flex-row {
    display: flex;
    div.col-* {
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 1200px) {
    .p-t-lg-20 {
        padding-top: 20px !important;
    }
}

// CSS for tablet
@media(min-width: 768px) and (max-width: 991px) {
    .m-t-sm-40 {
        margin-top: 40px !important;
    }
}

// CSS for mobile
@media(max-width: 767px) {
    .m-t-xs-40 {
        margin-top: 40px !important;
    }
    .p-t-xs-10 {
        padding-top: 10px !important;
    }
    .p-t-xs-20 {
        padding-top: 20px !important;
    }
    .p-t-xs-30 {
        padding-top: 30px !important;
    }
    .flex-row {
        display: initial;
    }
}

a,
input,
textarea,
button,
select {
    outline: none;
}

a:hover,
a:focus,
input:focus,
textarea:focus,
button:focus,
select:focus {
    outline: none !important;
    text-decoration: none;
}

body {
    position: relative;
    font-family: "NeueHaasUnicaPro";
    font-size: 14px;
    color: #232323;
    padding: 0;
    margin: 0;
    letter-spacing: 0.014em;
}

p {
    line-height: 1.7em;
    letter-spacing: 0.02em;
    font-size: 16px;
}

input,
textarea {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

input[type="search"],
input[type="text"],
input[type="url"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="file"] {
    background: none;
    border: 1px solid #d4d4d4;
    background-color: #fff;
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    color: #666;
    font-size: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* font-family: "Montserrat"; */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

select {
    color: #232323;
    /* font-family: "Montserrat"; */
    font-size: 12px;
}

textarea {
    background: none;
    border: 1px solid #d4d4d4;
    background-color: #fff;
    resize: none;
    padding: 12px;
    height: 140px;
    color: #666;
    font-size: 14px;
    vertical-align: middle;
}

input::-webkit-input-placeholder {
    color: #888888;
}

input:-moz-placeholder {
    color: #888888;
}

input::-moz-placeholder {
    color: #888888;
}

input:-ms-input-placeholder {
    color: #888888;
}

textarea::-webkit-input-placeholder {
    color: #888888;
}

textarea:-moz-placeholder {
    color: #888888;
}

textarea::-moz-placeholder {
    color: #888888;
}

textarea:-ms-input-placeholder {
    color: #888888;
}

a {
    color: #232323;
}

code,
kbd,
pre,
samp {
    font-size: inherit;
}

code {
    background-color: #eee;
    letter-spacing: 0.015em;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border: 0;
}

::-moz-selection {
    color: #fff;
    background-color: #232323;
}

::selection {
    color: #fff;
    background-color: #232323;
}

.element-invisible {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
}

#page-wrap {
    overflow: hidden;
    background-color: #f7f7f7;
}

.awe-parallax {
    background-position: 50% 50%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index: 0;
}

.fix-background-ios {
    -webkit-background-size: auto 150% !important;
    background-attachment: scroll !important;
}

.awe-static {
    color: #fff;
    background-position: 50% 50%;
    background-attachment: scroll;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.awe-color {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    width: 100%;
    height: 100%;
    background-color: #232323;
}

.awe-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(72, 72, 72, 0.3);
    top: 0;
    left: 0;
    z-index: 0;
}

.awe-btn {
    display: inline-block;
    background: none;
    /* font-family: "Montserrat"; */
    white-space: nowrap;
    /*text-transform: uppercase;*/
    cursor: pointer;
    line-height: normal;
    padding: 8px 10px;
    font-size: 12px;
    color: #000;
    min-width: 110px;
    border-width: 0;
    text-align: center;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.awe-btn.awe-btn-default {
    border: 2px solid #000;
}

.awe-btn.awe-btn-default:hover {
    border-color: #344a71;
    background-color: #344a71;
    color: #fff;
}

.awe-btn.awe-btn-1 {
    border: 2px solid #357ebd;
    color: #357ebd;
}

.awe-btn.awe-btn-1:hover {
    background-color: #357ebd;
    color: #fff;
}

.awe-btn.awe-btn-2 {
    border: 2px solid #4cae4c;
    color: #4cae4c;
}

.awe-btn.awe-btn-2:hover {
    background-color: #4cae4c;
    color: #fff;
}

.awe-btn.awe-btn-3 {
    border: 2px solid #46b8da;
    color: #46b8da;
}

.awe-btn.awe-btn-3:hover {
    background-color: #46b8da;
    color: #fff;
}

.awe-btn.awe-btn-4 {
    border: 2px solid #eea236;
    color: #eea236;
}

.awe-btn.awe-btn-4:hover {
    background-color: #eea236;
    color: #fff;
}

.awe-btn.awe-btn-5 {
    border: 2px solid #d43f3a;
    color: #d43f3a;
}

.awe-btn.awe-btn-5:hover {
    background-color: #d43f3a;
    color: #fff;
}

.awe-btn.awe-btn-6 {
    border: 2px solid #344a71;
    background-color: #344a71;
    color: #fff;
}

.awe-btn.awe-btn-6:hover {
    color: #344a71;
    background-color: transparent;
}

.awe-btn.awe-btn-7 {
    border: 2px solid #357ebd;
    background-color: #357ebd;
    color: #fff;
}

.awe-btn.awe-btn-7:hover {
    color: #357ebd;
    background-color: transparent;
}

.awe-btn.awe-btn-8 {
    border: 2px solid #4cae4c;
    background-color: #4cae4c;
    color: #fff;
}

.awe-btn.awe-btn-8:hover {
    color: #4cae4c;
    background-color: transparent;
}

.awe-btn.awe-btn-9 {
    border: 2px solid #46b8da;
    background-color: #46b8da;
    color: #fff;
}

.awe-btn.awe-btn-9:hover {
    color: #46b8da;
    background-color: transparent;
}

.awe-btn.awe-btn-10 {
    border: 2px solid #eea236;
    background-color: #eea236;
    color: #fff;
}

.awe-btn.awe-btn-10:hover {
    color: #eea236;
    background-color: transparent;
}

.awe-btn.awe-btn-11 {
    border: 2px solid #d43f3a;
    background-color: #d43f3a;
    color: #fff;
}

.awe-btn.awe-btn-11:hover {
    color: #d43f3a;
    background-color: transparent;
}

.awe-btn.awe-btn-12 {
    border: 2px solid #fff;
    color: #fff;
}

.awe-btn.awe-btn-12:hover {
    color: #e7c130;
    background-color: #e7c130;
    border-color: #e7c130;
    color: #fff;
}

.awe-btn.awe-btn-13 {
    border: 2px solid #fc161d;
    background-color: #fc161d;
    color: #fff;
}

.awe-btn.awe-btn-13:hover {
    border-color: #05173c;
    background-color: #05173c;
}

.awe-btn.awe-btn-14 {
    border: 2px solid #000;
}

.awe-btn.awe-btn-14:hover {
    border-color: #e7c130;
    background-color: #e7c130;
    color: #fff;
}

section {
    position: relative;
}

.tb {
    display: table;
    width: 100%;
}

.tb-cell {
    display: table-cell;
    vertical-align: middle;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: normal;
    line-height: 1.5em;
    color: #232323;
    /*font-family: "Montserrat"; */
    margin: 0;
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
    color: inherit;
}

h1,
.h1 {
    font-size: 36px;
    font-weight: bold;
}

h2,
.h2 {
    font-size: 28px;
}

h3,
.h3 {
    font-size: 24px;
}

h4,
.h4 {
    font-size: 21px;
}

h5,
.h5 {
    font-size: 18.15px;
    font-weight: bold;
}

h6,
.h6 {
    font-size: 14.12px;
}

abbr {
    color: #a1d71a;
    border-width: 2px;
}

mark,
.mark {
    background-color: #232323;
}

.icon {
    display: inline-block;
    font-style: normal;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.hr {
    height: 1px;
    width: 100%;
}

.hr:after,
.hr:before {
    width: 50%;
    content: "";
    height: 1px;
}

.hr:after {
    float: right;
    background-color: #e3e3e3;
    background-image: -webkit-gradient(linear, left top, right top, from(#e3e3e3), to(#ffffff));
    background-image: -webkit-linear-gradient(left, #e3e3e3, #ffffff);
    background-image: -moz-linear-gradient(left, #e3e3e3, #ffffff);
    background-image: -ms-linear-gradient(left, #e3e3e3, #ffffff);
    background-image: -o-linear-gradient(left, #e3e3e3, #ffffff);
}

.hr:before {
    float: left;
    background-color: #ffffff;
    background-image: -webkit-gradient(linear, left top, right top, from(#ffffff), to(#e3e3e3));
    background-image: -webkit-linear-gradient(left, #ffffff, #e3e3e3);
    background-image: -moz-linear-gradient(left, #ffffff, #e3e3e3);
    background-image: -ms-linear-gradient(left, #ffffff, #e3e3e3);
    background-image: -o-linear-gradient(left, #ffffff, #e3e3e3);
}

.heading {
    margin: 0;
    color: #232323;
    font-size: 36px;
    font-weight: bold;
    /*font-family: "Montserrat";*/
    /*text-transform: uppercase;*/
    line-height: 1;
    position: relative;
}

.heading.white {
    color: #fff;
}

.sub-heading {
    color: #333333;
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 15px;
}

.shortcode-heading {
    color: #464646;
    /* font-family: "Montserrat"; */
    font-weight: normal;
    padding-bottom: 10px;
    font-size: 14px;
    margin: 0;
    border-bottom: 2px dotted #464646;
}

blockquote {
    border: 2px solid #232323;
    padding: 15px 25px;
    margin: 0;
}

blockquote p {
    margin: 0;
    color: #444444;
    font-weight: 600;
    font-style: italic;
    line-height: 1.428em;
}

blockquote p:before {
    content: "“";
    /* font-family: "Montserrat"; */
    font-size: 28px;
    margin-right: 10px;
    font-style: inherit;
}

blockquote p:after {
    content: "”";
    font-size: 28px;
    font-style: inherit;
    margin-left: 10px;
    /* font-family: "Montserrat"; */
}

.bg-white {
    background-color: #fff;
}

.bg-gray {
    background-color: #f1f1f1;
}

.bg-blue {
    background-color: #344a71;
}

.tabs {
    background-color: transparent;
    border-width: 0;
    padding: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.tabs .ui-tabs-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border-width: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.tabs .ui-tabs-nav li {
    background-color: transparent !important;
    background-image: none !important;
    border-width: 0 !important;
    margin: 0 40px 0 0 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.tabs .ui-tabs-nav li a {
    color: #232323 !important;
    font-weight: 500;
    padding: 0 !important;
    /*text-transform: uppercase;*/
    /*font-family: "Hind";*/
}

.tabs .ui-tabs-nav li:hover a,
.tabs .ui-tabs-nav li.ui-tabs-active a {
    color: #516d9b !important;
}

.tabs .ui-tabs-panel {
    padding: 0;
    margin-top: 30px;
}

.tabs.tabs-2 .ui-tabs-nav li {
    margin: 0 !important;
}

.tabs.tabs-2 .ui-tabs-nav li a {
    /* font-family: "Montserrat"; */
    padding: 7px 10px !important;
    border: 2px solid transparent;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
}

.tabs.tabs-2 .ui-tabs-nav li:hover a {
    color: #232323 !important;
    border-color: #464646;
}

.tabs.tabs-2 .ui-tabs-nav li.ui-tabs-active a {
    background-color: #344a71;
    color: #fff !important;
}

.accordion .ui-accordion-header {
    background-color: #344a71;
    color: #fff;
    padding: 10px 20px;
    /*font-family: "Hind";*/
    /*text-transform: uppercase;*/
    font-weight: normal;
    margin-top: 10px;
    border-width: 0;
    outline: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.accordion .ui-accordion-header .ui-accordion-header-icon {
    display: none;
}

.accordion .ui-accordion-header:first-child {
    margin-top: 0;
}

.accordion .ui-accordion-content {
    background-color: transparent;
    padding: 15px 20px;
    border: 2px solid #344a71;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.alert {
    line-height: 1.5em;
    margin: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.awe-calendar-wrapper {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.awe-calendar-wrapper .awe-calendar {
    padding: 0 30px 0 10px;
    background-color: transparent;
    color: #333333;
    width: 100%;
    border: 2px solid #333333;
    height: 40px;
    line-height: 36px;
}

.awe-calendar-wrapper .awe-calendar::-webkit-input-placeholder {
    color: #333333;
}

.awe-calendar-wrapper .awe-calendar:-moz-placeholder {
    color: #333333;
}

.awe-calendar-wrapper .awe-calendar::-moz-placeholder {
    color: #333333;
}

.awe-calendar-wrapper .awe-calendar:-ms-input-placeholder {
    color: #333333;
}

.awe-calendar-wrapper i {
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 14px;
    color: #333;
    pointer-events: none;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
}

.bootstrap-select.btn-group.awe-select {
    width: auto;
}

.bootstrap-select.btn-group.awe-select .dropdown-toggle {
    background-color: transparent;
    outline: none;
    border: 2px solid #fff;
    height: 40px;
    line-height: 36px;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.bootstrap-select.btn-group.awe-select .dropdown-toggle:focus {
    outline: none !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.bootstrap-select.btn-group.awe-select .dropdown-toggle .caret {
    color: #fff;
}

.bootstrap-select.btn-group.awe-select .dropdown-toggle .filter-option {
    color: #fff;
    /* font-family: "Montserrat"; */
    font-size: 12px;
}

.bootstrap-select.btn-group.awe-select .dropdown-menu {
    margin: 0;
    border-width: 0;
    padding: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.bootstrap-select.btn-group.awe-select .dropdown-menu ul li a {
    padding: 10px 20px;
    /* font-family: "Montserrat"; */
    color: #333;
    font-size: 12px;
    border-bottom: 1px solid #f1f1f1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.bootstrap-select.btn-group.awe-select .dropdown-menu ul li a:hover {
    background-color: #516d9b;
    color: #fff;
    border-bottom-color: #516d9b;
}

.bootstrap-select.btn-group.awe-select .dropdown-menu ul li:last-child a {
    border-bottom-width: 0;
}

.bootstrap-select.btn-group.awe-select .dropdown-menu ul li.selected a {
    background-color: #516d9b;
    color: #fff;
    border-bottom-color: #516d9b;
}

.tab-price table {
    width: 100%;
    vertical-align: middle;
}

.tab-price table caption {
    text-align: center;
    position: relative;
    padding: 0;
}

.tab-price table caption h2 {
    margin: 0;
    color: #333333;
    font-size: 16.37px;
    line-height: 50px;
    /*text-transform: uppercase;*/
    /* font-family: "Montserrat"; */
}

.tab-price table caption a {
    display: block;
    position: absolute;
    text-align: center;
    line-height: 50px;
    width: 40px;
    top: 0;
    bottom: 0;
    font-size: 18px;
    color: #333;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
}

.tab-price table caption a:hover {
    color: #e7c130;
}

.tab-price table caption a.prev {
    left: 0;
}

.tab-price table caption a.next {
    right: 0;
}

.tab-price table thead {
    border: 2px solid #333;
}

.tab-price table thead th {
    text-align: center;
    /* font-family: "Montserrat"; */
    font-size: 14px;
    text-transform: capitalize;
    font-weight: normal;
    padding: 15px 10px;
}

.tab-price table tr td {
    padding: 15px 10px;
    border: 2px solid #333;
    /* font-family: "Montserrat"; */
    font-size: 16px;
    color: #333333;
    text-align: center;
    position: relative;
}

.tab-price table tr td .date {
    font-size: 13.6px;
    position: absolute;
    top: 5px;
    right: 10px;
}

.tab-price table tr td.other-date .date {
    color: #b0afaf;
}

.tab-price table tr td.date-select {
    background-color: #e7c130;
    color: #fff;
}

.ui-datepicker {
    background: #ffffff;
    border-width: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    padding: 0;
    width: 320px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.ui-datepicker .ui-datepicker-header {
    background-color: #3f5f91;
    border-width: 0;
    padding: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-title {
    /* font-family: "Montserrat"; */
    font-size: 16px;
    color: #fff;
    /*text-transform: uppercase;*/
    font-weight: both;
    line-height: 50px;
}

.ui-datepicker .ui-datepicker-header .ui-corner-all {
    position: static;
    display: block;
    height: inherit;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-width: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.ui-datepicker .ui-datepicker-header .ui-corner-all .ui-icon {
    background: none;
    color: #fff;
    text-indent: 0;
    position: static;
    margin: 0;
    width: inherit;
    height: inherit;
    display: inline;
}

.ui-datepicker .ui-datepicker-header .ui-corner-all.ui-datepicker-prev {
    float: left;
}

.ui-datepicker .ui-datepicker-header .ui-corner-all.ui-datepicker-next {
    float: right;
}

.ui-datepicker .ui-datepicker-header .ui-corner-all.ui-state-hover {
    background-color: #e7c130;
}

.ui-datepicker .ui-datepicker-calendar {
    width: 100%;
    margin: 0;
}

.ui-datepicker .ui-datepicker-calendar thead th {
    text-transform: capitalize;
    color: #8ea6d4;
    /* font-family: "Montserrat"; */
    font-weight: normal;
    background-color: #516d9b;
    padding: 12px 5px;
}

.ui-datepicker .ui-datepicker-calendar td a {
    padding: 7px 5px;
    background: none;
    color: #232323;
    /* font-family: "Montserrat"; */
    border-width: 0;
    text-align: center;
}

.ui-datepicker .ui-datepicker-calendar td a.ui-state-active,
.ui-datepicker .ui-datepicker-calendar td a:hover {
    background-color: #516d9b;
    color: #fff;
}

.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today a {
    background-color: #e7e7e7;
}

.owl-controls .owl-prev,
.owl-controls .owl-next {
    color: #fff;
    font-size: 24px;
    position: absolute;
    z-index: 10;
    top: 50%;
    font-size: 13px;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    border: 2px solid #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
}

.owl-controls .owl-prev:hover,
.owl-controls .owl-next:hover {
    color: #e7c130;
    border-color: #e7c130;
}

.owl-controls .owl-prev {
    left: 10px;
}

.owl-controls .owl-next {
    right: 10px;
}

.owl-controls .owl-pagination {
    font-size: 0;
}

.owl-controls .owl-pagination .owl-page {
    font-size: 14px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border: 2px solid #fff;
    margin: 0px 2px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
}

.owl-controls .owl-pagination .owl-page.active {
    background-color: #fff;
}

.awe-overflow-h {
    overflow: hidden;
}

.awe-popup-overlay {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.awe-popup-overlay:before,
.awe-popup-overlay:after {
    content: "";
}

.awe-popup-overlay.in {
    visibility: visible;
    opacity: 1;
}

.awe-popup-wrap {
    position: fixed;
    z-index: 999999;
    left: 0;
    right: 0;
    padding-left: 15px;
    padding-right: 15px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    visibility: hidden;
    opacity: 0;
    top: 0;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.awe-popup-wrap.in {
    visibility: visible;
    opacity: 1;
}

.awe-popup-wrap .awe-popup-content {
    display: inline-block;
    text-align: left;
}

.awe-popup-wrap .awe-popup-close {
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
}

.awe-popup-wrap .awe-popup-close:after,
.awe-popup-wrap .awe-popup-close:before {
    content: "";
    height: 2px;
    width: 20px;
    background-color: #e7c130;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.awe-popup-wrap .awe-popup-close:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.awe-popup-wrap .awe-popup-close:before {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

.bg-1 {
    background-image: url("assets/images/banner/img-1.jpg");
}

.bg-2 {
    background-image: url("assets/images/banner/img-2.jpg");
}

.bg-3 {
    background-image: url("assets/images/banner/img-3.jpg");
}

.bg-4 {
    background-image: url("assets/images/banner/img-4.jpg");
}

.bg-5 {
    background-image: url("assets/images/banner/img-5.jpg");
}

.bg-6 {
    background-image: url("assets/images/banner/img-6.jpg");
}

.bg-7 {
    background-image: url("assets/images/banner/img-7.jpg");
}

.bg-8 {
    background-image: url("assets/images/banner/img-8.jpg");
}

.bg-9 {
    background-image: url("assets/images/banner/img-9.jpg");
}

.bg-10 {
    background-image: url("assets/images/banner/img-10.jpg");
}

.bg-11 {
    background-image: url("assets/images/banner/img-11.jpg");
}

.bg-12 {
    background-image: url("assets/images/banner/img-12.jpg");
}

.bg-13 {
    background-image: url("assets/images/banner/img-13.jpg");
}

.bg-14 {
    background-image: url("assets/images/banner/img-14.jpg");
}

.bg-15 {
    background-image: url("assets/images/banner/img-15.jpg");
}

.bg-16 {
    background-image: url("assets/images/banner/img-16.jpg");
}

.bg-17 {
    background-image: url("assets/images/banner/img-17.jpg");
}

.bg-18 {
    background-image: url("assets/images/banner/img-18.jpg");
}

.bg-19 {
    background-image: url("assets/images/banner/img-19.jpg");
}

.bg-20 {
    background-image: url("assets/images/banner/img-20.jpg");
}

.bg-21 {
    background-image: url("assets/images/banner/img-21.jpg");
}

.bg-guias-turisticas {
    background-image: url("assets/images/banner/guias-turisticas2.jpg");
    margin-bottom: 30px;
}
.bg-concierge {
    background-image: url("assets/images/concierge/parallax-min.png");
    margin-bottom: 30px;
}
.bg-seguro {
    background-image: url("assets/images/seguro.jpg");
    margin-bottom: 30px;
}
.bg-contactos {
    background-image: url("assets/images/contactos1.jpg");
    margin-bottom: 30px;
}
.bg-preguntas-frecuentes{
    background-image: url("assets/images/preguntas-frecuentes.jpg");
    margin-bottom: 30px;
}
.bg-discount-pass {
    background-image: url("assets/images/banner/discount-pass-banner.jpg");
    // margin-bottom: 30px;
}

.bg-eventos-culturales {
    background-image: url("assets/images/banner/eventos-culturales2.jpg");
    margin-bottom: 30px;
}

.bg-cupones {
    margin-bottom: 30px;
}

section.section-intro {
    padding-top: 44px;
    padding-bottom: 25px;
}

/* SUB BANNER */
.section-sub-banner {
    padding-top: 80px;
}
.sub-banner {
    position: relative;
}
.sub-banner .text {
    padding-bottom: 50px;
}
.sub-banner .text h2 {
    margin-top: 70px;
    margin-bottom: 0;
    color: #fff;
    font-size: 35.68px;
    font-family: "NeueHaasUnicaPro";    
    /*text-transform: uppercase;*/
}
.sub-banner .text p {
    margin-bottom: 0;
    color: #fff;
    font-size: 18px;
}
/* END / SUB BANNER */

.intro .text li {
    font-weight: 700;
    color: #05173c;
    padding: 10px;
}

.text-white {
    color: #fff !important;
}

.text-center {
    text-align: center;
}

.section-sub-banner {
    padding-top: 80px;
}

.page-navigation {
	margin-top: 60px;
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
	font-size: 0;
	li {
		display: inline-block;
		font-size: 15px;
		margin-right: 20px;
		vertical-align: middle;
		a {
			color: #444444;
			-webkit-transition: all 0.2s ease;
			-moz-transition: all 0.2s ease;
			-ms-transition: all 0.2s ease;
			-o-transition: all 0.2s ease;
			&:hover {
				color: #fc161d;
			}
		}
	}
	li.first {
		a {
			font-size: 13px;
			display: block;
			border: 2px solid #232323;
			width: 23px;
			height: 23px;
			line-height: 22px;
			text-align: center;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			border-radius: 100%;
			&:hover {
				border-color: #fc161d;
			}
		}
	}
	li.last {
		a {
			font-size: 13px;
			display: block;
			border: 2px solid #232323;
			width: 23px;
			height: 23px;
			line-height: 22px;
			text-align: center;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			border-radius: 100%;
			&:hover {
				border-color: #fc161d;
			}
		}
	}
	li.current-page {
		font-weight: bold;
		font-size: 20px;
	}
}
.sidebar {
    margin-right: 70px;

    .widget {
        margin-top: 40px;

        .widget-title {
            color: #232323;
            font-size: 18px;
            margin-top: 0;
            margin-bottom: 20px;
            /* font-family: 'Montserrat'; */
            font-weight: bold;
            /*text-transform: uppercase;*/
        }
    }

    .widget_categories ul {
        margin-top: -7px;

        li {
            a {
                color: #333333;
                display: inline-block;
                padding: 7px 0;
                -webkit-transition: all 0.2s ease;
                -moz-transition: all 0.2s ease;
                -ms-transition: all 0.2s ease;
                -o-transition: all 0.2s ease;
            }
        }
    }
}

.no-margin {
    margin: 0 !important;
}

#preloader {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99999;
    background-color: #f1f1f1;
}

#preloader .preloader-dot {
    width: 0;
    height: 0;
    border: 2px solid #05173c;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 1;
    animation: preloader 3s ease infinite;
    -ms-animation: preloader 3s ease infinite;
    -moz-animation: preloader 3s ease infinite;
    -webkit-animation: preloader 3s ease infinite;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
@-webkit-keyframes preloader {
    0% {
        border-width: 2px;
    }
    100% {
        border-width: 50px;
        opacity: 0;
    }
}
@-moz-keyframes preloader {
    0% {
        border-width: 2px;
    }
    100% {
        border-width: 50px;
        opacity: 0;
    }
}

@-ms-keyframes preloader {
    0% {
        border-width: 2px;
    }
    100% {
        border-width: 50px;
        opacity: 0;
    }
}

@-o-keyframes preloader {
    0% {
        border-width: 2px;
    }
    100% {
        border-width: 50px;
        opacity: 0;
    }
}

@keyframes preloader {
    0% {
        border-width: 2px;
    }
    100% {
        border-width: 50px;
        opacity: 0;
    }
}